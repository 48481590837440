import API from "../common/API"

const PublicProjectService = {
  getProject(orgId, projectId) {
    return API.get(`/public/organizations/${orgId}/projects/${projectId}`)
      .then(data => {
        return data.project
      })
      .catch(() => {
        return Promise.reject({ message: "Unable to retrieve Project " + projectId })
      })
  },

  getOrganizations() {
    return API.get("/public/organizations")
      .then(d => {
        return d
      })
      .catch(() => {
        return Promise.reject({ message: "Unable to retrieve Organizations" })
      })
  },

  getProjects(orgId) {
    return API.get(`/public/organizations/${orgId}/projects`)
      .then(data => {
        return data
      })
      .catch(() => {
        return Promise.reject({ message: "Unable to retrieve projects" })
      })
  },

  getProjectDatums(projectId, orgId, preset) {
    return API.get(`/public/energy/datums/projects/${projectId}?orgId=${orgId}&preset=${preset}`)
  },

  getProjectReadings(projectId, orgId) {
    return API.get(`/public/energy/readings/projects/${projectId}?orgId=${orgId}`)
  }
}

export default PublicProjectService
